import topLayerState from "../shared-services/globalState/topLayerState";

appModule.directive('farmyMobileHeader', ['$q', '$translate', '$http', function($q, $translate, $http) {
  return {
    restrict: 'E',
    scope: {},
    controller: ['$scope', '$rootScope', '$window', '$location', '$timeout', '$element', 'UserService', 'CartData', 'Hubs', 'OrderHelper', 'UserNotifications', '$uibModal', 'UiStateService',
      function($scope, $rootScope, $window, $location, $timeout, $element, UserService, CartData, Hubs, OrderHelper, UserNotifications, $uibModal, UiStateService) {
        window.farmyMobileHeader = $scope;
        $scope.I18n = I18n;

        let unOnHubsChanged;

        function constructor() {
          $timeout(hideTrustedshops, 50);
          unOnHubsChanged = $rootScope.$on('hubs:changed', onHubsChanged);

          $rootScope.$on('express:change_started', () => {
            $scope.expressDeliveryOngoingChange = true;
          })

          $rootScope.$on('express:change_finished', () => {
            $scope.expressDeliveryOngoingChange = false;

            if ($scope.deliveryBlockDrawerBehaviour) {
              $scope.deliveryBlockVisible = true;
              preventDeliveryScrollCheckFor(1800)
            }
          })

          $rootScope.$on('express:changed', (e, args) => {
            $scope.expressDeliveryChecked = args.express;
          })

          let deliveryTermsWatcher = $rootScope.$on('hubs:delivery_terms:loaded', () => {
            $scope.setDeliverySectionVisibility();
            // deliveryTermsWatcher();
          })

          $scope.deliveryBlockElement = document.querySelector('.delivery-section');
        }

        function preventDeliveryScrollCheckFor(milliseconds = 500) {
          $scope.preventDeliveryScrollCheck = true;
          $timeout(() => {
            $scope.preventDeliveryScrollCheck = false;
          }, milliseconds)
        }

        function checkIfDeliveryShouldShow() {
          let currentScroll = window.scrollY;

          if (!$scope.preventDeliveryScrollCheck)
            if (currentScroll < lastScroll) {
              $scope.deliveryBlockVisible = true;
              preventDeliveryScrollCheckFor();
            } else if (currentScroll > lastScroll) {
              $scope.deliveryBlockVisible = false;
            }

          $scope.setDeliverySectionVisibility();

          lastScroll = currentScroll;
        }

        function destructor() {
          window.farmyMobileHeader = null;
          if (unOnHubsChanged) unOnHubsChanged();
        }

        function hideTrustedshops() {
          $("[id^=tsbadgeResponsive]").hide();
          $("[id^=tsbadgeResponsive]").css({position: 'absolute', visibility: 'hidden', overflow: 'hidden', display: 'none !important', height: '0', 'margin-bottom': '0'});
          $('[id^=MobileCoveringLayer]').hide();

          if ($("[id^=tsbadgeResponsive]").length == 0) {
            setTimeout(hideTrustedshops, 1000);
          }
        }

        function onHubsChanged(e) {
          $scope.currentHubCode = Hubs.currentHub.code;
        }

        $scope.sidebarVisible = false;
        $scope.cartSidebarVisible = false;

        $scope.UserService = UserService;
        $scope.UserNotifications = UserNotifications;

        $scope.currentHubCode = window.currentHubCode;

        $scope.deliveryBlockDrawerBehaviour = Hubs.canDoExpressDelivery;
        $scope.deliveryBlockVisible = true;
        $scope.preventDeliveryScrollCheck = false;
        let lastScroll = 0;

        $scope.setDeliverySectionVisibility = function() {
          if (Hubs.canDoExpressDelivery) {
            document.addEventListener('scroll', checkIfDeliveryShouldShow);
            $scope.deliveryBlockDrawerBehaviour = true;
          } else {
            document.removeEventListener('scroll', checkIfDeliveryShouldShow);
            $scope.deliveryBlockDrawerBehaviour = false;
          }

          $scope.shouldShowDeliverySection = $scope.deliveryBlockVisible;
        };

        $scope.toggleSidebar = function() {
          $scope.sidebarVisible = window.farmyMobileSidebar.sidebarVisible = !window.farmyMobileSidebar.sidebarVisible;
          if ($scope.sidebarVisible) {
            const {emptyAboveHeaderDrawer} = topLayerState();
            emptyAboveHeaderDrawer();
          }
          if (window.farmyMobileSidebar) {
            window.farmyMobileSidebar.onVisibilityToggle();
          }
        };

        $scope.toggleCartSidebar = function() {
          if (!window.farmyCartSidebar.cartSidebarVisible) $rootScope.$broadcast("track-gtm-cart");
          $scope.cartSidebarVisible = window.farmyCartSidebar.cartSidebarVisible = !window.farmyCartSidebar.cartSidebarVisible;
          if (window.farmyMobileSidebar) window.farmyMobileSidebar.hideSidebar();
        };

        function getGoToCartLink() {
          const pathsToExclude = ["/cart", "/cashier", "/signup"];
          return pathsToExclude.some(path => window.location.pathname.includes(path)) ? '#' : '/cart';
        }

        $scope.goToCartLink = getGoToCartLink();

        $scope.$watch(function() {
          return window.location.pathname;
        }, function() {
          $scope.goToCartLink = getGoToCartLink();
        });

        $scope.expandSidebarSearch = function() {
          if (window.farmyMobileSidebar) {
            window.farmyMobileSidebar.showAndSearch();
          }
        };

        $scope.toggleNotifications = function() {
          if ($scope.notificationsPopupInstance != null && $scope.notificationsPopupInstance.close) {
            $scope.notificationsPopupInstance.close();
          }

          $scope.notificationsPopupInstance = $uibModal.open({
            templateUrl: `/ng/templates/user_accounts/notifications_popup.html?locale=${I18n.locale}`,
            controller: 'UserNotificationsPopupCtrl',
            size: 'md',
            resolve: {

            }
          });

          $scope.notificationsPopupInstance.result.then(() => {
            $scope.notificationsPopupInstance = null;
          }, () => { $scope.notificationsPopupInstance = null });
        };

        $scope.notificationPanelListener = {
          onNotificationPanelHidden: function() {
            $scope.notificationPanelVisible = false;
          }
        };

        // Borrowed from 'mobile-sidebar' to hide cart sidebar if open
        $scope.onNavigateLinkClick = function() {
          // Hide the cart sidebar and the menu sidebar if any are open
          if (window.farmyCartSidebar) UiStateService.cartSidebarVisible = window.farmyCartSidebar.cartSidebarVisible = false;
          $scope.sidebarVisible = window.farmyMobileSidebar.sidebarVisible = false;
        };

        /**
         * NB: This method has a clone in the website-header component
         */
        $scope.onLowCapacityWarningClick = function() {
          $location.path(CartData.nearestDeliveryTermLink);
        };

        $scope.getTemplateUrl = () => {
          return window.currentStorefront
            ? `/ng/templates/nav/header_mobile_${window.currentStorefront}.html?locale=${$scope.I18n.locale}`
            : `/ng/templates/nav/header_mobile.html?locale=${$scope.I18n.locale}`;
        };

        $scope.CartData = CartData;
        $scope.Hubs = Hubs;
        $scope.OrderHelper = OrderHelper;
        $scope.UiStateService = UiStateService;

        $scope.$on('$destroy', destructor);
        constructor();
      }],
    link: function (scope, element, attrs) {},
    template: "<ng-include src='getTemplateUrl()' />"
  };
}]);
