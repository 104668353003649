'use strict';

import {trackPageView} from '../../services/tracking-helper';
import globalState from "../../shared-services/globalState";

angular.module('app').controller('RegistrationViewCtrl', ['$scope', '$rootScope', '$timeout', '$window', '$routeParams', '$location', 'CartData', function ($scope, $rootScope, $timeout, $window, $routeParams, $location, CartData) {
  window.RegistrationViewCtrl = $scope;
  window.$routeParams = $routeParams;
  const {getGlobalState} = globalState();
  const {setPageCode} = getGlobalState("router");

  $scope.enableB2bSignup = window.enableB2bSignup;
  $scope.isB2b = false;
  $scope.backToUrl = $location.search().back_to_url ? $location.search().back_to_url : null;
  $scope.twoFactorVerificationPending = false;

  var unOnRegistration;

  function constructor() {
    unOnRegistration = $rootScope.$on('user:authenticated', () => { $timeout(() => { onUserRegistered() }, 10) });

    $scope.proceedToCheckout = $location.search().checkout;
    $scope.showLogin = $location.search().login;
    setPageCode("registration");

    $scope.$watch('isB2b', (newValue) => {
      if (window.UserRegistrationFormCtrl)
        window.UserRegistrationFormCtrl.isB2b = newValue;
    });

    trackPageView(`signup_page${$location.search()?.checkout === 't' ? '_checkout' : ''}`);

    $window.scrollTo(0, 0);
  }

  function destructor() {
    window.RegistrationViewCtrl = null;
    if (unOnRegistration) unOnRegistration();
  }

  function onUserRegistered() {
    // If user is new, we redirect to /cart and wait for phone verification
    // If user exists, we redirect to /cart to show merged cart
    let checkoutPath = "/cart";

    if ($scope.proceedToCheckout) {
      Alerts.success($translate.instant("messages.logged_in_succesfully"));
      $location.path(`${NgFrontendAppCtrl.locationLocalePrefix?.replace(/\/$/, "")}${checkoutPath}`).search({});
    } else {
      if ($scope.backToUrl)
        $location.url($scope.backToUrl);
      else
        $location.path("/");
    }
  }

  $scope.CartData = CartData;

  $scope.loginUser = {
    email: undefined,
    password: undefined
  };

  $scope.onLoginFormSubmit = function() {
    $scope.authenticationInProgress = true;

    UserService.authenticate($scope.loginUser.email, $scope.loginUser.password, $scope.loginUser.verify_token).then((user) => {
      if (user.pending_verification) {
        $scope.twoFactorVerificationPending = true;
        $scope.authenticationInProgress = false;

        if (user.pending_verification_message) {
          Alerts.info(user.pending_verification_message);
        }
      } else {
        $scope.twoFactorVerificationPending = false;
        $scope.authenticationInProgress = false;
      }
    }, error => {
      // Apply to scope to trigger UI refresh
      $scope.$apply(function () {
        $scope.authenticationInProgress = false;
      });
    });
  };

  $scope.onEnterKeyPress = function(e) {
    if (e.keyCode === 13 && $scope.tosConfirmed) UserRegistrationFormCtrl.onSubmitClicked()
  };

  $scope.dismiss = function() {
    $window.history.back();
  }

  $scope.$on('$destroy', destructor);
  constructor();
}

]);
