/* eslint-disable no-eval,camelcase */
import * as _ from "underscore";
import {getVariantDisplayPrice, setVariantDisplayPrices} from "../shared-services/product-data-parser";
import {globalModalQueue} from "../shared-services/globalState/topLayerState";
import frontendSettings from "../frontendSettings";
import {trackEvent} from "../services/tracking-helper";
import globalState from "../shared-services/globalState";

angular.module("app").controller("ProductItemCtrl", ["$rootScope", "$scope", "$window", "$element", "$location", "$timeout", "Alerts", "CartData", "$translate", "CatalogServiceHelper", "UserService", "Hubs", function($rootScope, $scope, $window, $element, $location, $timeout, Alerts, CartData, $translate, CatalogServiceHelper, UserService, Hubs) {
  $scope.productId = $element.attr("data-id") ? parseInt($element.attr("data-id")) : null;
  if (isNaN($scope.productId)) $scope.productId = null;

  const {Tracking, ahoy, safely, ProductNavigation, $} = window;

  const imageSizeRatio = 1.3207660416; // Used to scale the image container, while the iamge resource is loading
  let hdImageLoaded = false; // set to true when the HD image is loaded

  $scope.$element = $element;

  $scope.lineItemInCart = null;
  $scope.variantInCart = null;
  $scope.variantIndex = null;
  $scope.inCart = false;
  $scope.itemLayout = "full";
  $scope.afterCartAddRecommendationsVisible = false;

  // Determine item layout
  if ($element.hasClass("list-view")) $scope.itemLayout = "list";

  if ($scope.product != null) {
    $scope.productId = $scope.product.id;
    $scope.hubIds = $scope.product.hub_ids;
    $scope.variants = setVariantDisplayPrices($scope.product, UserService.currentUser);
    $scope.disabledSale = $scope.product.disabled_sale;
    updateProductAvailability($scope.product.available_to_add_to_cart);
    $scope.ageRestricted = $scope.product.age_restricted;
    $scope.portalProduct = $scope.product.portal_product;
    $scope.recipeProduct = $scope.product.recipe_product;
    $scope.no_link = $scope.product.no_link;
    $scope.productUrl = $scope.product.product_url;

    // TODO: Rating serialization is needed as well here for the default categories product view, otherwise ratings metadata will be empty
  } else {
    $scope.productUrl = $element.data("product-url");
    $scope.hubIds = $element.data("hub-ids");
    $scope.variants = eval($element.attr("data-variants"));
    $scope.disabledSale = eval($element.attr("data-disabled-sale"));
    updateProductAvailability($element.data("available") && isAvailableToCurrentHub($scope.hubIds));
    $scope.ageRestricted = eval($element.attr("data-age-restricted"));
    $scope.portalProduct = eval($element.attr("data-portal"));
    $scope.recipeProduct = eval($element.attr("data-is-recipe"));
    $scope.noLink = eval($element.attr("data-no-link"));
  }

  if (window.Rails.env === "development" && $scope.productId) {
    if (window.ProductItems == null) window.ProductItems = {};
    window.ProductItems[$scope.productId] = $scope;
  }

  $scope.productLink = $element.find(".product-link").attr("href");

  $scope.CartData = CartData;

  setGoogleAnalyticsListName();

  window.ProductItemCtrl = $scope;

  // Cart actions
  $scope.increaseCartQuantity = function(e) {
    let oldVariant;
    if ($scope.variants == null) {
      Alerts.warn("Bitte warten...");
      return;
    }

    if (!Hubs.currentZipcode) {
      const {pushToQueue} = globalModalQueue();
      sessionStorage.setItem("also_bought_modal_blocked", "true");
      pushToQueue("zipcode", {
        onClose: () => {
          const {getGlobalState} = globalState();
          const {currentZipcode} = getGlobalState("session");

          if (currentZipcode && $scope.product) {
            $scope.increaseCartQuantity();
          }
        }
      });

      return;
    }

    if ($scope.ageRestricted && !window.currentUserAgeVerified) {
      window.FarmyCartAgeVerificationPopup.show(function() {
        window.currentUserAgeVerified = true;
        $scope.increaseCartQuantity(null);
      });
      return;
    }

    if ($scope.variantIndex == null) {
      $scope.variantIndex = 0;

      const searchInsightsData = {};
      if (window?.productFromSearch?.queryID) searchInsightsData.query_id = window.productFromSearch.queryID;
      if (window?.productFromSearch?.trackingListName) searchInsightsData.trackingListName = window.productFromSearch.trackingListName;
      searchInsightsData.index_name = window?.productFromSearch?.indexName || frontendSettings.defaultAlgoliaIndexName;

      const algoliaEventName = window?.productFromSearch ? "PDP: Product Added to Cart after Search" : "PDP: Product Added to Cart";

      trackEvent(algoliaEventName, {
        product: {
          ...$scope.product,
          ...searchInsightsData
        },
        type: "algolia"
      });
      trackAddToCart();
    } else if ($scope.variantIndex < $scope.variants.length) {
      oldVariant = $scope.variants[$scope.variantIndex];
      $scope.variantIndex += 1;
    }

    // Fail-safe
    if ($scope.variantIndex >= $scope.variants.length) { $scope.variantIndex = $scope.variants.length - 1 }

    $scope.variantInCart = $scope.variants[$scope.variantIndex];

    if ($scope.variantInCart) $scope.inCart = true;

    afterCartAdd();

    return CartData.setCartVariant($scope.productId, {
      id: $scope.variantInCart.id,
      price: $scope.variantInCart.price,
      quantity_in_units: $scope.variantInCart.qiu,
      quantity_index: $scope.variantInCart.quantity_index
    }, addToCartReferrer(), null, null, {oldVariant, callerLocation: "productpage"}).then(() => {
      CartData.save();

      const modalBlocked = sessionStorage.getItem("also_bought_modal_blocked") === "true";

      if (sessionStorage.getItem("also_bought_modal_shown") !== "true" && !modalBlocked) {
        const {pushToQueue} = globalModalQueue();
        const productID = $scope.productId.toString();

        pushToQueue("AlsoBoughtModal", {productID});
      }
    }, error => {
      // Reset product on rejection
      if (error === "cart_blocked") {
        $scope.variantIndex = null;
        $scope.inCart = false;
        $scope.variantInCart = null;
      }
    });
  };

  $scope.decreaseCartQuantity = function(e) {
    let oldVariant;

    if ($scope.variantIndex === 0) {
      $scope.variantIndex = null;

      // Track event
      Tracking.sendCartRemoveEvent($scope.productId, $element.find(".item-title").text());
    } else if ($scope.variantIndex > 0) {
      oldVariant = $scope.variants[$scope.variantIndex];
      $scope.variantIndex -= 1;
    }

    // Fail-safe
    if ($scope.variantIndex < 0) $scope.variantIndex = null;

    if ($scope.variantIndex != null) {
      $scope.variantInCart = $scope.variants[$scope.variantIndex];
      return CartData.setCartVariant($scope.productId, {
        id: $scope.variantInCart.id,
        price: $scope.variantInCart.price,
        quantity_in_units: $scope.variantInCart.qiu,
        quantity_index: $scope.variantInCart.quantity_index
      }, null, null, null, {oldVariant, callerLocation: "productpage"}).then(r => CartData.save());
    } else {
      $scope.variantInCart = null;
      $scope.inCart = false;
      return CartData.setCartVariant($scope.productId, null, null, null, null, {oldVariant, callerLocation: "productpage"}).then(r => CartData.save());
    }
  };

  $scope.openProductPage = function(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if ($scope.noLink) {
      openProductPopup();
    } else {
      trackClick(function() {
        $location.path($scope.productUrl);
        // document.location = $scope.productUrl;

        // if (window.enableSPA) {
        //   UiStateManager.turboOpenPage($scope.productUrl);
        // } else {
        //   document.location = $scope.productUrl;
        // }
      });
    }
  };

  $scope.openSmartPresentation = function(event) {
    event.stopPropagation();
    event.preventDefault();

    if ($rootScope.NativeContainer.isApp) {
      openProductPopup();
    } else {
      $scope.openProductPage(event);
    }
  };

  // Private members:

  function updateLayout() {
    const imgElement = $element.find(".photo img");
    $scope.imageHeight = imgElement.css("height");

    $timeout(function() {
      const imageWidth = $element.width();

      if (imageWidth > 1) {
        imgElement.css({
          width: imageWidth,
          height: imageWidth / imageSizeRatio
        });
      }
    }, 1);

    imgElement.one("load", function(e) {
      $scope.imageHeight = $element.find(".photo img").css("height");
    });

    if ($scope.itemLayout === "full") {
      if ($element.find(".item-title").text().length > 65) {
        $element.find(".item-title").css({"font-size": "11px"});
      } else if ($element.find(".item-title").text().length > 50) {
        $element.find(".item-title").css({"font-size": "14px"});
      } else {
        $element.find(".item-title").css({"font-size": "inherit"});
      }
    } else {
      if ($element.find(".item-title").text().length > 50) {
        $element.find(".item-title").css({"font-size": "11px"});
      } else if ($element.find(".item-title").text().length > 65) {
        $element.find(".item-title").css({"font-size": "10px"});
      }
    }
  };

  function trackAddToCart() {
    const options = {
      googleAnalyticsProduct: googleAnalyticsProduct()
    };

    Tracking.addToCart($scope.productId, $element.find(".item-title").text(), options);

    ahoy.track("order-item-added", {
      order_id: $scope.CartData.cart.id,
      order_number: $scope.CartData.cart.number,
      product_id: $scope.product.id,
      variant_id: $scope.product.variants[$scope.variantIndex].id,
      hub_id: window.currentHubId,
      list_mode: CatalogServiceHelper.lastUsedListMode,
      sort_mode: CatalogServiceHelper.lastUsedSortMode,
      referrer: addToCartReferrer(),
      channel: window.xSessionChannel,
      trackingListName: window.productFromSearch?.trackingListName
    });
  }

  function trackClick(callback) {
    try {
      const options = {
        analyticsListName: $scope.analyticsListName,
        googleAnalyticsProduct: googleAnalyticsProduct()
      };

      Tracking.productClick($scope.productId, options, function() {
        callback();
      });
    } catch (e) {
      console.warn("trackClick failed");
      callback();
    }
  }

  function googleAnalyticsProduct() {
    return $element.data("google-analytics-product") || ($scope.product && $scope.product.google_analytics_product);
  }

  function getGoogleAnalyticsListName() {
    const listElement = getGoogleAnalyticsListElement($element);
    return listElement.data("analytics-list-name");
  }

  function getGoogleAnalyticsListElement(el) {
    return el.parents("[data-analytics-list-name]");
  }

  function setGoogleAnalyticsListName() {
    const listName = getGoogleAnalyticsListName();
    if (listName && listName.length > 0) {
      $scope.analyticsListName = listName;
    }
  }

  function addToCartReferrer() {
    let res = "";

    safely(function() {
      res = res + location.pathname + location.hash;
      if ($scope.analyticsListName && $scope.analyticsListName.length > 0) { res = res + ":" + $scope.analyticsListName }
    });

    return res;
  }

  function updateProductAvailability(isAvailable) {
    $scope.available = isAvailable;

    // Stubfix
    if ($scope.product && $scope.product.hub_ids) { $scope.product.availableInCurrentHub = isAvailableToCurrentHub($scope.product.hub_ids) }

    if (!isAvailable && window.currentStorefront !== "farmybusiness") {
      $element.find("a").removeClass("product-link");
      $element.find("a").addClass("no-link");
      $element.find("a").prop("href", "#");
    }
  }

  /**
   * Reflects changes of the cart state in the current line item control
   */
  function updateCartState() {
    $scope.lineItemInCart = _.find(CartData.cart.line_items, function(line_item) {
      return line_item.product_id === $scope.productId;
    });

    if ($scope.lineItemInCart) {
      $scope.variantInCart = $scope.lineItemInCart.variant;

      // Set the index of the variant
      $scope.variantIndex = null;

      _.each($scope.variants, function(variant, num) {
        if (variant.id === $scope.variantInCart.id) { $scope.variantIndex = num }
      });
    } else {
      $scope.variantIndex = $scope.variantInCart = null;
      $scope.inCart = false;
    }

    $scope.inCart = $scope.variantInCart != null;
  };

  function openProductPopup() {
    trackClick(function() {
      ProductNavigation.openProductPopup($scope.productId, {parentAnalyticsListName: $scope.analyticsListName});
    });
  }

  function afterCartAdd() {
    if (!window.currentStorefront) {
      showAfterCartAddRecommendations();
    }
  }

  function showAfterCartAddRecommendations() {
    $scope.afterCartAddRecommendationsVisible = true;
  }

  function loadHdImage() {
    const imgElement = $element.find(".photo img");
    const hdImageSrc = imgElement.data("hd-src");

    hdImageLoaded = true;

    if (imgElement.length > 0 && hdImageSrc) {
      imgElement.attr("src", hdImageSrc);
    }
  }

  function isAvailableToCurrentHub(hubIds) {
    return (window.currentHubId && _.any(hubIds)) ? _.include(hubIds, window.currentHubId) : true;
  }

  function loadHdImageWhenInViewport() {
    // var imgElement = $element.find('.photo img');

    var interval = setInterval(function() {
      if (!hdImageLoaded && $($element).is(":in-viewport")) {
        clearInterval(interval);
        loadHdImage();
      }
    }, 800);
  }

  function showUnavailableAlert() {
    Alerts.warn($translate.instant("disabled_sale_message"));
  }

  // Destruction
  $scope.$on("$destroy", function() {
    if (window.ProductItems && $scope.product) { window.ProductItems[$scope.product.id] = null }
    if (window.productFromSearch) { window.productFromSearch = null }
  });

  // Construction
  updateLayout();

  $window.addEventListener("resize", function(e) {
    updateLayout();
  });

  // Deactivate link href around the product thumb, to avoid propagation
  // when tapping line item controls
  // $element.find('.product-link').attr('href', '#');
  $element.find(".product-link, .product-url").click(function(e) {
    let toElementTagName, toElement;

    // This is a Firefox workaround
    if (e.toElement == null && e.target != null) {
      toElementTagName = e.target.tagName.toLowerCase();
      toElement = e.target;
    } else {
      toElementTagName = e.toElement.tagName.toLowerCase();
      toElement = e.toElement;
    }

    if (window.browserMobileDevice) {
      // Photo click
      let clickType = "info";

      if (($(toElement).hasClass(".view-product") || $(toElement).parents(".view-product").length > 0)) { clickType = "info" } else if ($(toElement).hasClass(".col-photo") || $(toElement).parents(".col-photo, .control-panel").length > 0) { clickType = "photo" }

      if (clickType === "photo") {
        if (!$scope.hover || $scope.inCart) { e.stopPropagation() }

        e.preventDefault();

        return false;
      } else if (clickType === "info" && !$scope.hover) {
        $scope.hover = true;
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else if (clickType === "info" && $scope.hover) {
        openProductPopup();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    } else {
      if (toElementTagName === "button" || $(toElement).hasClass("buttons") || $(toElement).hasClass(".control-panel-body") || $(toElement).hasClass("normal") || $(toElement).hasClass("mini-style") || $(toElement).hasClass("favorites-toggle")) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      } else if ($(toElement).parents(".control-panel").length > 0) {
        openProductPopup();
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }

    return true;
  });

  $scope.$watch("product", function(product) {
    if (product != null) {
      $scope.productId = product.id;

      $scope.variants = [];

      _.each(product.variants, function(variant) {
        if (variant.is_master || variant.hidden) { return }

        $scope.variants.push({
          id: variant.id,
          label: variant.quantity_label || variant.label,
          qiu: variant.quantity_in_units,
          price: variant.price,
          quantity_index: variant.quantity_index,
          displayPrice: getVariantDisplayPrice(variant, $scope.product, UserService.currentUser)
        });
      });

      $scope.variants = _.sortBy($scope.variants, function(variant) { return variant.qiu });

      $scope.recipeProduct = product.is_recipe;
      $scope.ageRestricted = product.age_restricted;
      $scope.disabledSale = product.disabled_sale;
      updateProductAvailability(product.available_to_add_to_cart && isAvailableToCurrentHub(product.hub_ids));
      $scope.portalProduct = product.portal_data;
      $scope.hubIds = product.hub_ids;

      updateCartState();

      if (!$scope.product.available_to_add_to_cart && !$scope.unavailableAlertShown) showUnavailableAlert();
    }
  });

  $scope.$watch("CartData.cart.updated_at", function(updatedAt) {
    if (updatedAt == null) { return }

    updateCartState();
  });

  $rootScope.$on("react:emptyCart", payload => {
    updateCartState();
  });

  // If element is NOT visible, watch its state to apply updateLayout() once it is.
  // Solves cart button misplacements in special blocks, like carousels.
  // https://farmyag.atlassian.net/browse/IM-596 (Explore Page Suppliers Block Bug)
  if (!$element.is(":visible")) {
    var visibilityWatcher = $scope.$watch(function() { return $element.is(":visible") }, function(isVisible) {
      if (isVisible) {
        updateLayout();
        visibilityWatcher();
      }
    });
  }

  // Remove links from the element, if needed
  if ($scope.noLink) {
    $element.find("a").attr("href", "#");
  }

  // Disable clicks for mobile app mode.
  if ($rootScope.NativeContainer.isApp) {
    $element.find("a[href]").attr("href", "#");
  }

  $timeout(loadHdImageWhenInViewport, 400);
}]);
