import * as _ from "underscore";
import * as sprintf from "sprintf";
import {trackEvent} from "./tracking-helper";
import {globalModalQueue} from "../shared-services/globalState/topLayerState";

angular.module("app").service("Hubs", ["$rootScope", "$q", "$http", "$interval", "$uibModal", "$localStorage", "$timeout", "$translate", "$location", function($rootScope, $q, $http, $interval, $uibModal, $localStorage, $timeout, $translate, $location) {
  const scope = this;
  const Alerts = window.Alerts;

  window.Hubs = this;

  // 'user_request' option must always be last!
  const openedForOptions = ["first_time", "second_time", "user_request"];

  $localStorage.zipcodePopupOpensFor = $localStorage.zipcodePopupOpensFor || "first_time";
  scope.allHubIds = [1, 4];
  scope.currentUserId = window.currentUserId;
  scope.currentUserHubId = window.currentUserHubId;
  scope.currentHubId = $rootScope.currentHubId = window.currentHubId;
  $rootScope.currentHubCode = window.currentHubCode;
  scope.currentOrderId = window.currentOrderId;
  scope.currentOrderToken = window.currentOrderToken;
  scope.numOfPageLoads = scope.numOfPageLoads || 0;

  if (scope.currentHub == null && window.currentHubId) {
    scope.currentHub = {
      id: scope.currentHubId,
      name: window.currentHubName,
      code: window.currentHubCode
    };

    if (window.currentZipcode) {
      scope.hubSetByUser = true;
    }

    scope.currentZipcode = window.currentZipcode;
    if (scope.currentZipcode) {
      $rootScope
        .$broadcast(
          "zipcode:changed",
          {zipcode: scope.currentZipcode, marketingZone: scope.currentMarketingZone}
        );
    }

    scope.canDoExpressDelivery = window.canDoExpressDelivery;

    $timeout(() => {
      scope.getDeliveryTerms();
    }, 1);
  }

  /**
   * Placeholder for hub and zipcode delivery terms
   * @type {{}}
   */
  scope.deliveryTerms = {};

  scope.zipcodeInfoCache = {};

  scope.getKnownZipcodes = function() {
    return $q((resolve, reject) => {
      if (window.knownZipcodes) {
        return resolve(window.knownZipcodes);
      } else {
        $http.get("/api/frontend/hubs/known_zipcodes.json").then((response) => {
          window.knownZipcodes = response.data.zipcodes;
          return resolve(window.knownZipcodes);
        }, (e) => { reject(e) });
      }
    });
  };

  scope.getHubById = function(id) {
    return $q((resolve, reject) => {
      const hub = _.find(window.knownHubs, (h) => h.id.toString() == id.toString());

      if (hub) { resolve(hub) } else { reject("not_found") }
    });
  };

  scope.getHubByZipcode = function(zipcode) {
    return $q((resolve, reject) => {
      scope.getKnownZipcodes().then((zipcodes) => {
        const record = _.find(zipcodes, (z) => z[0] != null && z[0].toString() == zipcode.toString());

        if (record) {
          const hubId = record[1];
          scope.getHubById(hubId).then((hub) => resolve(hub));
        } else reject("not_found");
      });
    });
  };

  /**
   * Get an info structure for an individual zipcode
   *
   * @param zipcode
   * @returns {*}
   */
  scope.getZipcodeInfo = function(zipcode) {
    if (zipcode == null) { zipcode = window.currentZipcode }

    return $q((resolve, reject) => {
      if (scope.zipcodeInfoCache[zipcode.toString()]) {
        return resolve(scope.zipcodeInfoCache[zipcode.toString()]);
      } else {
        $http.get("/api/frontend/hubs/zipcode_info.json", {params: {zipcode}}).then((response) => {
          if (response.data.unknown) {
            reject(response.data);
          } else {
            scope.zipcodeInfoCache[zipcode.toString()] = response.data;
            return resolve(scope.zipcodeInfoCache[zipcode.toString()]);
          }
        }, (e) => { reject(e) });
      }
    });
  };

  // Returns a promise that resolves to
  // { id: int, name: string, zipcodes: [array of strings] }
  scope.fetchCurrentHub = function() {
    return $q(function(resolve, reject) {
      $http.get("/api/frontend/hubs/current.json").then(function(response) {
        angular.extend(scope.currentHub || {}, response.data);
        resolve(scope.currentHub);
      });
    });
  };

  scope.setCurrentHubFromZipcode = function(zipcode, firstZipcodeSubmission = false) {
    return $q(async function(resolve, reject) {
      const previousHubId = window.currentHubId;
      try {
        const response = await $http.post("/hubs/apply.json", {zip: zipcode, locale: $translate.use(), update_order_id: scope.currentOrderId, update_order_token: scope.currentOrderToken});
        scope.currentZipcode = window.currentZipcode = window.currentUserZipcode = $localStorage.currentUserZipcode = response.data.zipcode.name;
        scope.currentMarketingZone = window.currentMarketingZone = response.data.marketing_zone;
        scope.canDoExpressDelivery = response.data.zipcode.can_do_express_delivery;
        scope.currentHub = angular.extend(scope.currentHub || {}, response.data.hub);
        window.currentHubCode = scope.currentHub.code || "zurich";
        window.currentHubId = scope.currentHub.id || 1;
        window.currentHubName = scope.currentHub.name;

        $rootScope.currentHubId = scope.currentHub.id;
        $rootScope.currentHubCode = scope.currentHub.code || "zurich";

        if (previousHubId !== scope.currentHub.id && !firstZipcodeSubmission) {
          $rootScope
            .$broadcast(
              "hubs:changed",
              {
                currentHub: scope.currentHub,
                currentZipcode: scope.currentZipcode,
                currentMarketingZone: response.data.marketing_zone
              }
            );
        }

        try {
          await trackEvent("select_location", {userDetails: {postal_code: zipcode}});
        } catch (trackError) {
          console.error("Error tracking select_location event", trackError);
          // reject(trackError);

          $rootScope.currentHubCode = scope.currentHub.code || "zurich";
          // throw trackError;
        }

        await scope.setCurrentHub(response.data.hub.id, {firstZipcodeSubmission});
        resolve(response.data.hub);
      } catch (error) {
        console.error("Error in setCurrentHubFromZipcode", error);
        reject(error);
      }
    });
  };

  scope.setCurrentZipcode = function(zipcode) {
    return $q((resolve, reject) => {
      // TODO: Fix obvious redundancy here
      scope.currentZipcode = window.currentZipcode = window.currentUserZipcode = $localStorage.currentUserZipcode = zipcode;

      scope.zipcodeInfoCache[zipcode] = {
        name: zipcode,
        hub_id: scope.currentHubId,
        marketing_zone: scope.currentMarketingZone
      };

      $rootScope
        .$broadcast(
          "zipcode:changed",
          {zipcode, currentMarketingZone: scope.currentMarketingZone}
        );
      resolve(zipcode);
    });
  };

  scope.setCurrentHub = function(hubId, firstZipcodeSubmission = false) {
    return $q((resolve, reject) => {
      const oldHubId = scope.currentHubId;
      // TODO: Fix obvious redundancy here
      scope.currentHubId = window.currentHubId = window.currentUserHubId = $localStorage.currentUserHubId = hubId;
      scope.hubSetByUser = true;
      $rootScope.currentHubId = hubId;
      $rootScope.currentHubCode = getHubCode(hubId);

      let eventToBroadcast = oldHubId !== hubId ? "hubs:changed" : null;
      if (eventToBroadcast) {
        eventToBroadcast = firstZipcodeSubmission ? "hubs:changed:firstTime" : eventToBroadcast;
        $rootScope.$broadcast(eventToBroadcast, {currentHub: scope.currentHub});
      }

      resolve(hubId);
    });
  };

  // Returns a promise that resolves to
  // { hubFromZipcode: { id: int, name: string }
  //   canSafelyChangeHub: bool,
  //   unavailableProducts: [{id: int, name: string}, {...}, ...]
  // }
  scope.fetchHubChangeStatus = function(zipcode) {
    const deferred = $q.defer();
    const responseObject = {};
    $http.get(sprintf("/api/frontend/hubs/from_zipcode?zipcode=%s", zipcode)).then(function(response) {
      responseObject.hubFromZipcode = response.data;
      responseObject.defaultLocale = response.data.default_locale;

      $http.get(sprintf("/api/frontend/orders/can_safely_change_hub.json?hub_id=%s", responseObject.hubFromZipcode.id)).then(function(response) {
        responseObject.canSafelyChangeHub = response.data.is_safe;
        responseObject.unavailableProducts = response.data.unavailable_products;
        deferred.resolve(responseObject);
      });
    }, function(error) {
      Alerts.error("Unknown zipcode error");
    });

    return deferred.promise;
  };

  scope.getDeliveryTerms = function(zipcode) {
    return $q((resolve, reject) => {
      const params = {
        hub_id: scope.currentHub.id,
        zipcode: zipcode || scope.currentZipcode,
        locale: $translate.use() || I18n.locale
      };

      $http.get("/api/frontend/hubs/delivery_terms.json", {params}).then(response => {
        scope.deliveryTerms = response.data;
        scope.canDoExpressDelivery = scope.deliveryTerms.zipcode && scope.deliveryTerms.zipcode.can_do_express_delivery;
        $rootScope.$broadcast("hubs:delivery_terms:loaded", response.data);
        resolve(response.data);
      });
    });
  };

  scope.openZipcodeModal = function() {
    const {pushToQueue} = globalModalQueue("zipcode");
    sessionStorage.setItem("also_bought_modal_blocked", "true");
    pushToQueue();
  };

  scope.openZipcodeModalIfNeeded = function() {
    // Check for special zipcode popup parameter
    // which will show the modal only after "X" number of page loads
    // configurable like: "?nzpXpl", where "X" is any number.
    // NOTE: page reload will either restart (if parameter present) or dismiss the counter.
    if (window.location.search.match(/nzp[0-9]+pl/)) {
      if (shouldShowZipcodeModal()) {
        const literalParameter = window.location.search;
        const targetNumOfPageLoads = parseInt(window.location.search.match(/[0-9]+/)[0]);

        const pageLoadCallback = $rootScope.$on("$locationChangeSuccess", (event, newUrl, oldUrl) => {
          // Some page loads trigger this event twice, thus the url comparison.
          if (scope.numOfPageLoads == 0 || (newUrl != oldUrl && newUrl != oldUrl.replace(literalParameter, ""))) {
            scope.numOfPageLoads++;
            if (scope.numOfPageLoads >= targetNumOfPageLoads) {
              $timeout(function() {
                if (shouldShowZipcodeModal()) {
                  scope.openZipcodeModal();
                }
              }, 1000);

              pageLoadCallback();
            }
          }
        });
      }
    }

    // Check for special 'ignore zipcode requirement' parameter, that sometimes
    // must be used for better first-time user experience
    if (window.location.search.match(/(\?nzp)|(&nzp)/g) || window.browserIsBot || window.prerenderAgent) { return }

    // Exclude signup, login and password/edit.
    // Also special case for the order map tracking screen
    // (because it's usually access outside of the regular workflow)
    const exceptionPages = ["login", "signup", "positional_tracking", "password/edit"];
    if (window.location.href.match(exceptionPages.join("|").replace("/", "\/"))) { return }

    $timeout(function() {
      if (shouldShowZipcodeModal()) {
        scope.openZipcodeModal();
      }
    }, 1000);
  };

  scope.setZipcodePopupToShow = function() {
    let index = openedForOptions.indexOf($localStorage.zipcodePopupOpensFor);
    if (index > -1) {
      index += 1;
      if (openedForOptions[index]) $localStorage.zipcodePopupOpensFor = openedForOptions[index];
    } else {
      $localStorage.zipcodePopupOpensFor = openedForOptions[0];
    }
  };

  scope.getZipcodeHub = function() {

  };

  scope.getZipcodeHubId = function() {
    return $q((resolve, reject) => {

    }, (e) => {
      reject(null);
    });
  };

  scope.deliveryDatesForZipcode = function() {
    return $q((resolve, reject) => {
      $http.get(`/api/farmy/delivery_slots/nav_delivery_dates?zipcode=${scope.currentZipcode}`, {params: {locale: I18n.locale}}).then((response) => {
        resolve(response.data.dates);
      }, (e) => { reject(e) });
    });
  };

  scope.onNextDeliveryDateClick = function() {
    const path = window.CartData.isExpressDelivery
      ? $translate.instant("mobile_header.next_delivery_block.link_url.xpress")
      : $translate.instant("shipping_costs.delivery_cost_details_page.link");

    if (scope.deliveryTerms.nearest_slot && !scope.currentZipcode) {
      scope.openZipcodeModal({redirectUrl: path, newTab: true});
    } else {
      window.open(path, "_blank");
    }
  };

  // Private members
  function shouldShowZipcodeModal() {
    if (window.CartData && window.CartData.cartBlocked) {
      return true;
    } else {
      return !scope.hubSetByUser &&
        !window.preventPopups &&
        !window.browserIsBot;
    }
  }

  function getHubCode(hubId) {
    return {
      1: "zurich",
      4: "lausanne"
    }[hubId] || "zurich";
  }

  const subscriptions = [];

  subscriptions.push(
    $rootScope.$on("hubs:changed", (e) => {
      scope.getDeliveryTerms();
    })
  );

  subscriptions.push(
    $rootScope.$on("react:zipcode:changed", (e, data) => {
      scope.setCurrentZipcode(data?.zipcode);
    })
  );

  subscriptions.push(
    $rootScope.$on("$translateChangeSuccess", () => {
      scope.getDeliveryTerms();
    })
  );

  subscriptions.push(
    $rootScope.$on("user:authenticated", (e) => {
      if (window.UserService.currentUser.hub_id && window.UserService.currentUser.last_used_zipcode) {
        scope.setCurrentHubFromZipcode(window.UserService.currentUser.last_used_zipcode);
      }
    })
  );

  $rootScope.$on("$destroy", () => {
    subscriptions.forEach(unsubscribe => unsubscribe());
  });
}]);
