import {getCallerLocation} from "./tracking-helper";

const eventNameDictionary = {
  addtocart: "add_to_cart",
  removefromcart: "remove_from_cart",
  order: "purchase",
  promotion_click: "select_promotion",
  product: "view_item",
  cartview: "view_cart",
  basket: "view_cart",
  register: "sign_up",
  outboundLink: "outbound_link"
};
const skipTracking = [];
const categoriesToRemove = ["market", "marketv3"];
const formatProducts = (products = null) => {
  if (!products) return null;
  products = Array.isArray(products) ? products : [products];
  return products.map(product => {
    const productObj = {
      item_id: `${product?.product_id || product?.id || product?.productData?.id}`,
      item_name: product?.google_analytics_product?.name || product?.name || product?.product_name || product?.product?.name || product?.productData?.name,
      price: parseFloat(product?.unit_price || product?.productData?.price || product?.price),
      item_brand: product?.supplier_name || product?.google_analytics_product?.brand || product?.supplier?.name || product?.product?.supplier?.name || product?.productData?.supplier?.name,
      netto: parseFloat(product?.unit_price || product?.productData?.price || product?.price),
      unit_name: product?.unit_name || product?.productData?.unit_name || product?.product?.unit_name || product?.weight_units
    };

    if (product?.weight_control_billing || product?.productData?.weight_control_billing) {
      const weightSurcharge = product?.weight_control_surcharge || product?.productData?.weight_control_surcharge;
      const weightDeviation = product?.weight_control_deviation || product?.productData?.weight_control_deviation;
      const surcharge = (!weightSurcharge && weightSurcharge !== 0) ? product?.price * weightDeviation : weightSurcharge;
      productObj.weight_surcharge = parseFloat((surcharge).toFixed(2));
    }

    const quantity = product?.trackingQuantity || product?.variant?.quantity_in_units || product?.variant?.variant_based_quantity || 1;

    if (quantity) productObj.quantity = quantity;

    const categories = getCategories(product);

    if (categories) {
      categories.forEach((category, index) => {
        productObj[`item_category${index > 0 ? `${index + 1}` : ""}`] = category?.name?.trim();
      });
    }
    return productObj;
  });
};

const getCategories = (product) => {
  let categories = product?.category_name ||
    product?.google_analytics_product?.category ||
    product?.productData?.breadcrumbs_categories ||
    product?.breadcrumbs_categories ||
    product?.product?.breadcrumbs_categories ||
    product?.breadcrumbs ||
    product?.categories;

  if (categories) {
    if (!Array.isArray(categories)) {
      categories = categories.split("/");
      categories = categories.map(category => ({name: category}));
    }
    categories = categories.filter(category => !categoriesToRemove.includes(category?.name?.toLowerCase()));
  }
  return categories;
};

const formatUserIdentifiers = (userDetails) => {
  if (!userDetails) return null;
  const addressInfo = {
    first_name: userDetails?.first_name,
    last_name: userDetails?.last_name,
    street: userDetails?.street_address,
    zipcode: userDetails?.postal_code,
    city: userDetails?.city,
    country: userDetails?.country,
    state: userDetails?.state || ""
  };
  return {
    address_info: addressInfo,
    hashed_email: userDetails?.email,
    hashed_phone_number: userDetails?.phone
  };
};

const formatProductCount = (productTotalCount) => {
  let results = "no_result";
  if (productTotalCount) {
    results = "1-24";
    if (productTotalCount > 200) results = "201+";
    else if (productTotalCount > 100) results = "101-200";
    else if (productTotalCount > 50) results = "51-100";
    else if (productTotalCount > 24) results = "25-50";
  }
  return results;
};

const parseOrder = (track, order) => {
  const dryIce = order?.dry_ice_total || order?.adjustments?.find(adjustment => adjustment.code === ":dry_ice")?.amount;
  const freeShipping = order?.ship_total === 0 || order?.adjustments?.find(adjustment => adjustment.code === ":smart_pass");
  const parsedCheckout = {
    coupon: order?.coupon_code || "",
    discount_total: Math.abs(order?.discount_total || 0),
    promo_total: Math.abs(order?.promo_total || 0),
    itemtotal: parseFloat(order?.item_total),
    shipping: freeShipping ? 0 : order?.ship_total,
    tax: parseFloat(order?.vat_amount).toFixed(2) || 0,
    dryice: dryIce || 0,
    handcut: order?.weight_control_surcharge_total,
    smallorder: order?.handling_fee,
    currency: order?.currency || window.defaultCurrency,
    value: parseFloat(order?.total_including_surcharges) || parseFloat((order.total + order.weight_control_surcharge_total).toFixed(2)),
    item_total_no_vat: order?.item_total_no_vat || 0
  };

  if (order?.payment_method_name) parsedCheckout.payment_method_name = order.payment_method_name;

  if (track === "checkout") {
    parsedCheckout.total_value = parsedCheckout.value;
    delete parsedCheckout.value;
  }

  if (track === "purchase") {
    parsedCheckout.first_order = order?.first_order;
    parsedCheckout.order_affiliation = "Online Store";
    parsedCheckout.transaction_id = `${order?.number}`; // We track order number in id field
  }

  if (track === "add_shipping_time" && order?.shipping_tier) parsedCheckout.shipping_tier = order?.shipping_tier;
  if (track === "view_cart") parsedCheckout.url = window.location.pathname + window.location.search;

  return parsedCheckout;
};

// Events
const gdlTrackEvent = (eventName, options = null) => {
  if (!eventName) return;
  eventName = options?.gaEventName || eventNameDictionary[eventName] || eventName;
  delete options?.gaEventName;

  const products = options?.products;
  const checkout = options?.checkout;
  const userDetails = options?.userDetails;

  if (skipTracking.includes(eventName)) return;
  let trackingObject = {};

  if (products) {
    trackingObject.items = formatProducts(products);
    trackingObject.currency = window.defaultCurrency;
  }

  if (checkout) {
    const parsedCheckout = parseOrder(eventName, checkout);
    trackingObject = {...trackingObject, ...parsedCheckout};
  }

  if (userDetails) {
    if (eventName === "purchase") {
      trackingObject.user_identifiers = formatUserIdentifiers(userDetails);
      trackingObject.user_identifiers.email = userDetails.no_hashed_email;
      trackingObject.adjustment_type = "ENHANCEMENT";
    } else {
      for (const key in userDetails) {
        if (userDetails[key] || userDetails[key] === 0) {
          trackingObject[key] = userDetails[key];
        }
      }
    }
  }

  if (options?.searchTerm) {
    options.productsTotalCount = formatProductCount(options?.productsTotalCount);
  }

  if (["add_to_cart", "remove_from_cart", "product_removed_from_cart", "empty_cart"].includes(eventName)) {
    trackingObject.value = parseFloat(options?.gtmObject?.value || options?.gtmValue?.value);
  } else if (["view_item", "add_to_wishlist", "remove_from_wishlist"].includes(eventName)) {
    trackingObject.value = parseFloat(trackingObject?.items[0]?.price);
    if (eventName === "view_cart") trackingObject.url = window.location.pathname + window.location.search;
  } else if (!["begin_checkout", "add_shipping_info", "add_shipping_time", "add_payment_info", "purchase"].includes(eventName)) {
    if (options) {
      const {gtmObject, products, checkout, userDetails, ...rest} = options;
      if (gtmObject) {
        trackingObject = {...trackingObject, ...gtmObject, ...rest};
      } else {
        trackingObject = {...trackingObject, ...rest};
      }
    }
  }
  delete trackingObject.eventName;

  const dataLayerObject = {
    event: eventName,
    ecommerce: trackingObject
  };

  const trackingListName = options?.trackingListName || window.productFromSearch?.trackingListName;

  if (trackingListName) dataLayerObject.trackingListName = trackingListName;

  if (["add_to_cart", "remove_from_cart"].includes(eventName)) {
    dataLayerObject.caller_location = options?.callerLocation || getCallerLocation();
  }

  if (window.dataLayer == null) window.dataLayer = [];
  window.dataLayer.push({ecommerce: null});
  window.dataLayer.push(dataLayerObject);
};

export {gdlTrackEvent};
