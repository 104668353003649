import  { trackGoogleDistanceMatrixApiCall } from '../faro';
import * as _ from 'underscore';

angular.module('app').controller('OrderPositionalTrackingCtrl', ['$scope', '$interval', '$http', '$location', 'UserService', function($scope, $interval, $http, $location, UserService) {
  window.OrderPositionalTrackingCtrl = $scope;

  const updateLocationInterval = 7000;
  const updateDistanceInterval = 60000;
  let mapOptions = {};
  let map;
  let distance;
  const trafficModel = 'bestguess'; // options: bestguess, pessimistic and optimistic
  let firstLoad = true;

  let etaBox;

  $scope.orderToken = $location.search().token || $location.search().order_token;

  const farmyGetoTag = document.createElement("img");
  farmyGetoTag.src = "/farmy-geo-tag.png";
  farmyGetoTag.style.width = "64px";
  farmyGetoTag.style.height = "64px";

  const tractorTag = document.createElement("img");
  farmyGetoTag.src = "/tractor-animated.gif";
  farmyGetoTag.style.width = "64px";
  farmyGetoTag.style.height = "64px";

  function constructor() {
    $scope.courier = {
      location: {lat: 0, lng: 0},
      label: 'o',
      marker: null
    };
    $scope.destination = {
      label: 'd',
      marker: null
    };

    $scope.estimatedArrivalTime = '';

    $scope.onRoute = true;
    $scope.centeredFirstTime = false; // If we centered on the courier once after first load

    $scope.mapOptions = {};

    $scope.$watch('orderId', function(newValue) {
      if (newValue === null || map != null || window.browserIsBot) {
        return;
      }

      updateLocation();
      if ($scope.shipped) {
        return;
      }

      initializeMap();

      $interval(updateLocation, updateLocationInterval);
      $interval(updateDistance, updateDistanceInterval);
    });
  }

  $scope.onCenterClick = function() {
    map.panTo($scope.courier.marker.position);
  };

  var initializeMap = function() {
    var blockHeight = Math.round($(window).height() * 0.68) + 'px';

    $('#map').css('height', blockHeight);
    $('#shipped-notice').css('height', blockHeight).css('lineHeight', blockHeight);

    $scope.mapOptions = {
      defaultCenter: true,
      center: new google.maps.LatLng(47.3844028, 8.4930511),
      zoom: 16,
      disableDefaultUI: false,
      styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
      mapId: "order-tracking-map"
    };

    map = new google.maps.Map($('#map')[0], $scope.mapOptions);
    distance = new google.maps.DistanceMatrixService;
  };


  var updateLocation = function() {
    let params = {};

    if ($scope.orderToken != null && $scope.orderToken.length > 1) {
      params.order_token = $scope.orderToken;
    }

    return $http.get('/api/frontend/orders/' + $scope.orderId + '/positional_tracking.json', { params: params })
      .then(function(response) {
        var data = response.data;
        $scope.onRoute = data.shipment_state == 'en_route' ? true : false;

        if (data.location)
          $scope.courier.location = data.location;

        $scope.shipped = data.shipped;

        if(data.destination && !$scope.destination.info) {
          $scope.destination.info = data.destination;
        }
      }).then(function() {
        if ($scope.shipped) {
          return;
        }
        updateMarkers();
        if (firstLoad) {
          updateDistance();
          firstLoad = false;
        }
      });
  };

  var updateMarkers = function() {
    if (!$scope.destination.marker) {
      $scope.destination.marker = new google.maps.marker.AdvancedMarkerElement({
        position: {lat: $scope.destination.info.latitude, lng: $scope.destination.info.longitude},
        map,
        content: farmyGetoTag,
        title: $scope.destination.info.address1 + ', ' + $scope.destination.info.city
      });
    }

    if ($scope.courier.location) {
      if (!$scope.courier.marker) {
        $scope.courier.marker = new google.maps.marker.AdvancedMarkerElement({
          position: {lat: parseFloat($scope.courier.location.lat), lng: parseFloat($scope.courier.location.lng)},
          map,
          optimized: false,
          content: tractorTag,
          title: "Bestellung en route"
        });

        if(!$scope.centeredFirstTime) {
          $scope.centeredFirstTime = true;
          map.panTo({ lat: parseFloat($scope.courier.location.lat), lng: parseFloat($scope.courier.location.lng) });
        }
      } else {
        $scope.courier.marker.position = {lat: parseFloat($scope.courier.location.lat), lng: parseFloat($scope.courier.location.lng)};
      }
    } else {
      if ($scope.courier.marker) {
        $scope.courier.marker.map = null;
      }
    }

    if (mapOptions.defaultCenter) {
      mapOptions.center = new google.maps.LatLng($scope.courier.marker.position.lat, $scope.courier.marker.position.lng);
      map.center = mapOptions.center;
      mapOptions.defaultCenter = false;
    }
  };

  var updateDistance = function() {
    if ($scope.shipped) {
      return;
    }

    if (etaBox == null) {
      etaBox = $('#eta-info');
      if ($(window).width() > 767) {
        map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(etaBox[0]);
      }
    }

    $('#eta-info').css('bottom', '12px');

    if ($scope.courier.location && $scope.destination.info) {
      var courierLat = parseFloat($scope.courier.location.lat);
      var courierLng = parseFloat($scope.courier.location.lng);
      if (courierLat === 0 && courierLng === 0) {
        // If the courier's location is not available, we don't want to make a distance matrix API call
        return;
      }

      var courierPosition = {lat: courierLat, lng: courierLng};
      var destinationPosition = {lat: $scope.destination.info.latitude, lng: $scope.destination.info.longitude};

      let distanceMatrixApiOpts = {
        origins: [courierPosition],
        destinations: [destinationPosition],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        drivingOptions: {
          departureTime: new Date(),
          trafficModel: trafficModel
        }
      }

      trackGoogleDistanceMatrixApiCall({
        order_id: $scope.orderId,
        user_id: UserService.currentUser.id,
        user_ip: $scope.orderLastIpAddress,
        params: distanceMatrixApiOpts
      })
      distance.getDistanceMatrix(distanceMatrixApiOpts, function(response, status) {
        $scope.estimatedArrivalTime = response.rows[0].elements[0].duration_in_traffic.text;
      });
    }
  };

  // Initialization
  $scope.$on('$destroy', function() {
    window.OrderPositionalTrackingCtrl = null;
  });

  window.loadGoogleMapsCallback(() => {
    $scope.googleMapsLoaded = true;
    constructor()
  });
}]);