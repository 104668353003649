import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackEvent} from "../services/tracking-helper";
import globalState from "../shared-services/globalState";

'use strict';

/**
 * Listens to shipping zipcode changes and runs cart contents validations related
 * to the shipping destination (Hubs compatibility, etc.)
 *
 * A visual element of the shipping stage is linked to scope variables
 * modified by this method.
 */
angular.module('app')
  .directive('farmyMobileSidebar', function() {
    return {
      controller: ['$rootScope', '$scope', '$http', '$q', '$window', '$timeout', '$interval', '$location', 'Alerts', 'blockUI', '$attrs', '$element', '$localStorage', 'CartData', 'Hubs', 'TaxonHelper', 'OrderHelper', 'UserService', 'UiStateService', 'SmartPassService', '$translate',
        function ($rootScope, $scope, $http, $q, $window, $timeout, $interval, $location, Alerts, blockUI, $attrs, $element, $localStorage, CartData, Hubs, TaxonHelper, OrderHelper, UserService, UiStateService, SmartPassService, $translate) {
          window.farmyMobileSidebar = $scope;

          let unOnHubsChanged;

          function constructor() {
            $scope.preferredLocale = $translate.use();

            angular.element($window).bind('resize', () => { updateLayout() });
            angular.element($window).bind('scroll', () => {
              updateLayout();
              $timeout(() => { updateLayout() });
            });

            $rootScope.$on('$translateChangeSuccess', function () {

            });

            unOnHubsChanged = $rootScope.$on('hubs:changed', onHubsChanged);

            // Make the body fixed on show, to prevent scrolling issues
            $scope.$watch('sidebarVisible', (newValue, oldValue) => {
              if (newValue != oldValue) {
                if (newValue) UiStateService.setBodyScroll(false);
                else UiStateService.setBodyScroll(true);
              }
            });

            const {getGlobalState} = globalState();

            $scope.$watch(
              function() {
                return getGlobalState("topLayer").aboveHeaderDrawer;
              },
              function(newValue, oldValue) {
                $scope.sidebarTopSpecial = newValue?.name?.length;
              }
            );


            updateLayout();
            onHubsChanged()
          }

          function destructor() {
            window.farmyMobileSidebar = null;
          }

          function updateLayout() {
            // TODO
          }

          function onHubsChanged(e) {
            $scope.currentHubCode = Hubs.currentHub.code;
          }

          // Note: since this issue https://farmyag.atlassian.net/browse/IM-4006
          // taxons might be included/excluded from the list of rootTaxon.children which populates the sidebar menu.
          function loadCategories() {
            $scope.isSecondTry = $scope.isSecondTry || false;
            let taxonIdsToInclude = [];
            let taxonIdsToExclude = [];
            let kidsItemsTaxonId = 1188;
            let veganCornerTaxonId = 608;

            let petFoodTaxonId = 1210;
            let chemAndCosmeticsTaxonId = 1061;

            if (window.currentStorefront == 'bio_suisse') {
              taxonIdsToExclude = [veganCornerTaxonId, petFoodTaxonId, chemAndCosmeticsTaxonId, kidsItemsTaxonId];
            } else {
              taxonIdsToInclude = [];
              taxonIdsToExclude = [veganCornerTaxonId];
            }

            let allTaxonIds = [window.rootCategoriesTaxonId].concat(taxonIdsToInclude);
            let onlyOneTaxon = allTaxonIds.length == 1;
            let actionToPerform = onlyOneTaxon ? TaxonHelper.getTaxonInfo : TaxonHelper.getTaxonInfos;

            // Retrieve both rootTaxon and additionalTaxons
            actionToPerform(allTaxonIds, { include_direct_children: true, seo_url: true }).then((response) => {
              // In some cases the first request returns an empty array.
              // Cuses still to be determined.
              // TODO: check that TaxonHelper is ready before calling "getTaxonsInfo";
              if (!response || response.length == 0) {
                if (!$scope.isSecondTry) {
                  $scope.isSecondTry = true;
                  $timeout(() => {
                    loadCategories()
                  }, 300)
                }

                return;
              }

              // TaxonHelper.getTaxonInfo (in singular) will return a single object,
              // whereas TaxonHelper.getTaxonInfos (in plural) will return an array.
              // We need to work with an array in any case.
              if (onlyOneTaxon)
                response = [response];

              let rootTaxon = _(response).find((t) => t.id == window.rootCategoriesTaxonId);
              let additionalTaxons = _(_(response).reject(t => t.id == rootTaxon.id)).map(t => _(t).pick('id', 'name', 'permalink', 'seo_url', 'depth'));

              // Final result: root.children - excluded + included.
              let menuTaxons = _(_(rootTaxon.children).reject((t) => taxonIdsToExclude.includes(t.id)).concat(additionalTaxons)).uniq((i) => i.id);

              // Sort by depth:
              $scope.menuTaxons = _.sortBy(menuTaxons, t => t.depth);
            })
          }

          // debugging
          $scope.loadCategories = () => { loadCategories() }

          $scope.menuTaxons = null;
          $scope.sidebarVisible = false;
          $scope.sidebarWithSearchVisible = false;
          $scope.UserService = UserService;
          $scope.SmartPassService = SmartPassService;

          $scope.onTemplateLoaded = function() {
            // Add some DOM listeners/ui fixes etc
            loadCategories();
            updateLayout();
          };

          $scope.hideSidebar = function() {
            $scope.sidebarVisible = false;
            $scope.sidebarWithSearchVisible = false;
            $scope.onVisibilityToggle();
          };

          $scope.hideSidebarAndCloseSearch = function(closeSearch = true) {
            $scope.hideSidebar();
            $rootScope.$broadcast('search:close');
          };

          $scope.showSidebar = function() {
            $scope.sidebarVisible = true;
            $scope.onVisibilityToggle();
          };

          $scope.showAndSearch = function() {
            if ($scope.sidebarWithSearchVisible) {
              $scope.hideSidebar();
              return;
            }

            $scope.sidebarVisible = true;

            let tmpTouchHandler =  (e) => {
              $($element.find('input.search-input')).off('touchstart', tmpTouchHandler);
              $element.find('input.search-input').focus();
            }

            $($element.find('input.search-input')).on('touchstart', tmpTouchHandler);
            $($element.find('input.search-input')).trigger('touchstart', { });

            $scope.onVisibilityToggle();
            $scope.sidebarWithSearchVisible = true;
          };

          $scope.onVisibilityToggle = function() {
            $scope.preferredLocale = $translate.use();
          };

          $scope.onNavigateLinkClick = function(event, label) {
            // track navigation click
            if (label) {
              trackEvent(`navigation_${label}`);
            }
            // Hide the cart sidebar if it is open
            $timeout(() => { $scope.hideSidebar() }, 150);
          };

          $scope.openZipcodeModal = function(opensFor) {
            if (window.browserIsBot || window.prerenderAgent) return;

            $timeout(() => { $scope.hideSidebar() }, 50);

            if (!$rootScope.isLoadingPopup) {
              $localStorage.zipcodePopupOpensFor = 'user_request';
              opensFor = opensFor || null;

              var previousOpenfor = opensFor ? $localStorage.zipcodePopupOpensFor : null;
              $localStorage.zipcodePopupOpensFor = opensFor || $localStorage.zipcodePopupOpensFor;
              $rootScope.isLoadingPopup = true;

              Hubs.openZipcodeModal();

              $localStorage.zipcodePopupOpensFor = previousOpenfor ? previousOpenfor : $localStorage.zipcodePopupOpensFor;
            }
          };

          $scope.unfoldCategory = function(taxon) {
            return $q((resolve, reject) => {
              // Lazy-load category children
              if (taxon.children == null || taxon.children.length == 0) {
                TaxonHelper.getTaxonInfo(taxon.id, { include_direct_children: true, seo_url: true }).then((t) => {
                  taxon.children = taxon.children || [];
                  _.each(t.children, c => taxon.children.push(c));

                  taxon._unfolded = true;
                  resolve(taxon)
                })
              } else {
                taxon._unfolded = true;
                resolve(taxon)
              }
            })
          };

          $scope.foldCategory = function(taxon) {
            return $q((resolve, reject) => {
              taxon._unfolded = false;
              resolve(taxon)
            })
          };

          $scope.toggleCategory = function(taxon) {
            return $q((resolve, reject) => {
              if (taxon._unfolded) return $scope.foldCategory(taxon);
              else return $scope.unfoldCategory(taxon)
            })
          };

          $scope.setLocale = function(locale) {
            $timeout(() => { $scope.hideSidebar() }, 100);

            if ($translate.use() != locale)
              $translate.use(locale);
          };

          // Initialize

          $scope.$on('$destroy', destructor);
          constructor();
        }],
      scope: {

      },
      link: function($scope, $element, attributes) {

      },
      template: `<farmy-ng-include src="'/ng/templates/nav/sidebar_mobile${window.currentStorefront ? '_' + window.currentStorefront : ''}.html'" onload="onTemplateLoaded()"></farmy-ng-include>`
      // templateUrl: function() { return `/ng/templates/nav/sidebar_mobile.html?locale=${window.I18n.locale}` }
    }
  });
