import * as _ from 'underscore';
import globalState from "../../shared-services/globalState";

'use strict';

angular.module('app').controller('SubscribePageSmartPassNewCtrl', ['$scope', '$http', '$rootScope', '$uibModal', 'PromotionsService', '$location', 'UserService', 'SmartPassService', '$q', 'FarmyNotifications',
  function($scope, $http, $rootScope, $uibModal, PromotionsService, $location, UserService, SmartPassService, $q, FarmyNotifications) {
    window.SubscribePageSmartPassNewCtrl = $scope;
    $scope.subscribePageCtrl = window.PaymentsSubscribePageCtrl;
    $scope.smartPassService = SmartPassService;
    $scope.userService = UserService;
    $scope.continueAnyway = true;
    $scope.payNow = $scope.subscribePageCtrl.payNow = true;
    $scope.addressIsUsed = false;
    const {getGlobalState} = globalState();
    const {setPageCode} = getGlobalState("router");

    function constructor() {
      $scope.subscribePageCtrl.actionText = 'subscribe';
      setPageCode("farmypass_subscribe");

      $scope.spDuration = parseInt($location.search()['sp-duration']);
      $scope.spType = $location.search()['sp-type'];

      if ($location.search().status) {
        $scope.status = $location.search().status;
        $scope.responseMessage = $location.search().response_message;
        $scope.onContinueAnyway();
      }

      if ($scope.status) launchPaymentErrorMessage();

      $scope.userId = window.currentUserId;

      $scope.removeSmartPass = false;

      if ($scope.sourceId) {
        loadSourceSmartPassSubscription().then(result => {
          if ($scope.sourceSmartPassSubscription) {
            loadAddress($scope.sourceSmartPassSubscription.address.id).then(response => {
              $scope.initialAddress = response;
              setStartingSubscriptionFromSource();
            });
          } else {
            setActiveSmartPassesWatcher();
          }
        });
      } else {
        setActiveSmartPassesWatcher();
      }
    }

    function launchPaymentErrorMessage() {
      let message = $translate.instant('paid_subscription.unknown_gateway_error');

      if ($scope.responseMessage && $scope.responseMessage.length > 0)
        message = `${$translate.instant('smart_pass.alerts.cannot_perform_operation')}<br><i>"${$scope.responseMessage}"</i>`

      FarmyNotifications.error(message);
    }

    function setActiveSmartPassesWatcher() {
      let activeSmartPassesWatcher = $scope.$watch('smartPassService.subscriptions', (subscriptions) => {
        if ($scope.smartPassService.activeSubscriptions().length > 0) {
          $scope.activeSubscriptions = $scope.smartPassService.activeSubscriptions();
          $scope.onOtherSmartPassesPresent()
        }

        activeSmartPassesWatcher()
      }, true);
    }

    function loadAddress(addressId) {
      return $q((resolve, reject) => {
        $http.get(`api/frontend/addresses/${addressId}.json`).then(response => {
          resolve(response.data)
        }, e => reject(e))
      })
    }

    function loadSourceSmartPassSubscription() {
      return $q((resolve, reject) => {
        $scope.sourceSmartPassSubscription = _($scope.smartPassService.subscriptions).find(s => s.id == $scope.sourceId);

        if ($scope.sourceSmartPassSubscription) {
          resolve($scope.sourceSmartPassSubscription)
        } else {
          $scope.smartPassService.loadSubscriptions({all: true}).then(result => {
            $scope.sourceSmartPassSubscription = _($scope.smartPassService.subscriptions).find(s => s.id == $scope.sourceId);
            if ($scope.sourceSmartPassSubscription)
              resolve($scope.sourceSmartPassSubscription)
            else
              reject('Hofpass not found')
          })
        }
      })
    }

    function setStartingSubscriptionFromSource() {
      if (!$scope.sourceSmartPassSubscription) return;

      $scope.spType = $scope.sourceSmartPassSubscription.level;
      $scope.spDuration = $scope.sourceSmartPassSubscription.duration;
    }

    function destructor() {
      window.SubscribePageSmartPassNewCtrl = null;
    }

    // #### Event handlers ####
    $scope.onOtherSmartPassesPresent = function() {
      $scope.continueAnyway = false;
      $scope.showOtherSmartPassesWarning = true;
      $scope.subscribePageCtrl.showSubmit = false;
      $scope.subscribePageCtrl.enableSubmit = false;

      $scope.usedAddresses = ($scope.activeSubscriptions).map(s => s.address.full_address);
    };

    $scope.onContinueAnyway = function() {
      $scope.continueAnyway = true;
      $scope.showOtherSmartPassesWarning = false;
      $scope.setSubmitButton();
    };

    // Called from OneLineAddressForm and selector, with a {address: address, isValid: bool} payload as argument.
    $scope.onCurrentAddressChanged = function(payload) {
      payload = payload || {};

      $scope.addressIsUsed = false;
      let newAddress = payload.address;

      if (!newAddress){
        $scope.zipcode = null;
        return
      }

      $scope.zipcode = newAddress.zipcode;
      $scope.isAddressValid = payload.isValid;

      if (!$scope.isAddressValid || !$scope.zipcode || $scope.zipcode.length < 4 || newAddress.city.length == 0) {
        $scope.zipcode = null;
        $scope.setSubmitButton();
        return;
      }

      $scope.currentAddress = newAddress;

      $scope.addressIsUsed = $scope.usedAddresses && $scope.usedAddresses.length > 0 && $scope.usedAddresses.indexOf($scope.currentAddress.full_address) > -1;
      if ($scope.addressIsUsed) {
        $scope.zipcode = null;

        let existingSPS = _($scope.activeSubscriptions).find(s => s.address.full_address == $scope.currentAddress.full_address)
        let existingPaidSubscription = existingSPS ? existingSPS.paid_subscription : null;

        $scope.existingSubscriptionId = existingPaidSubscription ?
                                        existingPaidSubscription.id :
                                        existingSPS ? existingSPS.id : null;
      }
      $scope.setSubmitButton()
    };

    // Called from SmartPassSelector component, with the selected smartPass as argument.
    $scope.onSpSelectionChanged = function(selectedSmartPass) {
      selectedSmartPass = selectedSmartPass || null;

      $scope.selectedSubscription = selectedSmartPass;

      if (!$scope.selectedSubscription) return;

      const total = parseFloat($scope.selectedSubscription.total);
      const price = parseFloat($scope.selectedSubscription.strikeout_total || $scope.selectedSubscription.total);

      $scope.subscribePageCtrl.subscription.totalPrice = total;
      $scope.subscribePageCtrl.subscription.nextPaymentDate = $scope.nextPaymentDate();
      $scope.subscribePageCtrl.subscription.nextPaymentAmount = price;

      $scope.subscribePageCtrl.subscription = $scope.subscribePageCtrl.subscription || {};
      $scope.subscribePageCtrl.subscription.default_amount = price;
      $scope.subscribePageCtrl.subscription.amount = total;

      const smartPassSubscription = {
        sp_type: $scope.typeFromParams,
        level: $scope.selectedSubscription.level,
        duration: $scope.selectedSubscription.duration,
        user_id: $scope.userId,
        order_id: $scope.orderId,
        price: total
      };

      $scope.subscribePageCtrl.subscription.source_parameters = {
        action: 'create',
        smart_pass_subscription: smartPassSubscription,
        pay_now: true
      };

      $scope.setSubmitButton();
    };

    // #### Getters ####
    $scope.isSelectedAddressValid = function() {
      if (!$scope.currentAddress || !$scope.isAddressValid) return false;

      let values = _(_($scope.currentAddress).pick('zipcode', 'firstname', 'lastname', 'address1', 'city')).values();
      return !_(values).contains('') && !_(values).contains(null)
    };

    $scope.nextPaymentDate = function() {
      return moment().add(parseInt($scope.selectedSubscription.duration), 'months').toDate()
    };

    // #### Setters ####
    $scope.setSubmitButton = function(reset) {
      reset = reset || null;

      if (reset || !$scope.selectedSubscription) {
        $scope.subscribePageCtrl.showSubmit = false;
        $scope.subscribePageCtrl.enableSubmit = false;
        return;
      }

      if ($scope.selectedSubscription && $scope.userId) {
        $scope.subscribePageCtrl.showSubmit = true;
        $scope.subscribePageCtrl.enableSubmit = true;
      } else {
        $scope.subscribePageCtrl.showSubmit = false;
        $scope.subscribePageCtrl.enableSubmit = false;
      }
    };

    $scope.changeType = function(type) {
      if($scope.spType != type){
        $scope.spType = type;
        $scope.onSpSelectionChanged();
      }
    };

    $scope.setPremiumOnly = function() {
      $scope.premiumOnly = true;
      $scope.changeType('premium');
    };

    // Init
    constructor();
    $scope.$on('destroy', destructor)
  }]);
