'use strict';

import globalState from "../../shared-services/globalState";

angular.module('app').controller('InvoiceIndexPageCtrl', ['$scope', function ($scope) {
  console.log('InvoiceIndexPageCtrl loaded with scope')
  const {getGlobalState} = globalState();
  const {setPageCode} = getGlobalState("router");
  setPageCode("invoice_index");
  $scope.$watch('userId', function(val) {
    if (!UserService.isLoggedIn) UserService.showLoginScreen({forcePageReload: false});
  });
  }
]);
