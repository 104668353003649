import * as _ from 'underscore';
import {trackEvent} from '../../services/tracking-helper';
import currentTheme from "../../react-themes/theme";
import globalState from "../../shared-services/globalState";

angular.module('app').controller('CatalogViewCtrl', ['$scope', '$rootScope', '$q', '$location', '$window', '$http', '$element', '$timeout', '$compile', 'Alerts', 'TaxonHelper', 'ProductNavigation', '$uibModal', 'Hubs', 'CatalogServiceHelper', '$translate', '$sce', '$localStorage', 'UiStateService', 'UserService', 'PartnerPixelHelper', 'CartData',
  function ($scope, $rootScope, $q, $location, $window, $http, $element, $timeout, $compile, Alerts, TaxonHelper, ProductNavigation, $uibModal, Hubs, CatalogServiceHelper, $translate, $sce, $localStorage, UiStateService, UserService, PartnerPixelHelper, CartData) {
    window.CatalogViewCtrl = $scope;
    window.CatalogRoot = $rootScope;
    $scope.isLoading = true;
    CatalogServiceHelper.setLoading(true);
    const {getGlobalState} = globalState();
    const {setPageCode} = getGlobalState("router");

    $scope.cartData = CartData;

    const DEFAULT_SORTING = 'popularity';

    var unbindCatalogFiltersChanged;

    function constructor() {
      $scope.initialLocale = window.I18n?.locale;
      $scope.theme = currentTheme();
      $window.scrollTo(0, 0);
      setPageCode("catalog");
      $rootScope.$broadcast("triggerFreeDeliveryBannerRender");

      $scope.sortMode = $location.search().sort_mode || DEFAULT_SORTING;

      $rootScope.$on('hubs:changed', function(event, options) {
        // Products and filters data must be cleared
        $scope.currentTaxon = null;
        if ($scope.products) $scope.products = [];

        $timeout(() => {
          $scope.update();
        }, 500);
      });

      $rootScope.$on('catalog:filters:changed', function(event, options) {
        if (!_destroyed) {
          $scope.update({resetProducts: true, resetPage: true});

          if (window.CatalogFilterCtrl) {
            let newFilters = window.CatalogFilterCtrl.getSelection();
            $rootScope.$broadcast('filtersUpdated', newFilters);
          }
        }
      });


      $rootScope.$on('catalog:filters:master:changed', function(event, options) {
        if (!_destroyed) {
          $scope.update({resetProducts: true, resetPage: true});
        }
      });

      unbindCatalogFiltersChanged = $rootScope.$on('$translateChangeSuccess', function (event, options) {
        // Products and filters data must be cleared
        if ((options?.language) === $scope.initialLocale) {
          $scope.initialLocale = options?.language;
          return;
        }

        $scope.currentTaxon = null;
        if ($scope.products) $scope.products = [];

        $timeout(() => {
          $scope.update();
        }, 500);
      });

      // The deferred call is required to allow child filter components
      // correctly process url parameters and assign filter selections,
      // before the final product list is requested (so that the request
      // will include filter parameters from the URL.
      $timeout(() => { $scope.update() });

      PartnerPixelHelper.categoryView($scope.taxonData);
      setupPopAlgorithm();
    }

    function destructor() {
      if (_destroyed)
        return;

      window.CatalogViewCtrl = null;

      if ($scope.currentLoadProductsRequest && $scope.currentLoadProductsRequest.resolve) {
        $scope.currentLoadProductsRequest.resolve();
      }

      // Make sure to free reference to the infinite scroll
      // so that it is reinitialized
      if (window.productsInfiniteScroll != null) {
        window.productsInfiniteScroll.destroy();
        window.productsInfiniteScroll = null;
      }

      if ($scope.paginator) {
        $scope.paginator.destroy();
        $scope.paginator = null;
      }

      if (unbindCatalogFiltersChanged) unbindCatalogFiltersChanged();

      _destroyed = true; // This flag will be checked by potential hanging listeners that continue to work after destroy is called
    }

    // Public
    $scope.CatalogServiceHelper = CatalogServiceHelper;
    $scope.UiStateService = UiStateService;

    $scope.getCurrentTaxonId = function() {
      return $scope.currentTaxonId;
    };
    window.getCurrentTaxonId = $scope.getCurrentTaxonId;

    $scope.taxonData = JSON.parse($element.attr('data-initial-taxon-data'));
    $scope.currentTaxonId = $scope.taxonId = $scope.taxonId || $scope.taxonData.id;

    $scope.currentParentTaxonId = $scope.taxonData.parent ? $scope.taxonData.parent.id : null;
    $scope.currentParentTaxonName = $scope.taxonData.parent ? $scope.taxonData.parent.name : null;

    $scope.taxonName = $scope.taxonData.name;

    if ($element.find('.additional-information-block .original').length > 0) {
      $scope.taxonDescription = $element.find('.additional-information-block .original')[0].innerHTML;
    }

    $scope.$location = $location;
    $scope.$translate = $translate;
    $scope.$sce = $sce;

    $scope.breakpointOnLoadView = false;

    $scope.listMode = CatalogServiceHelper.lastUsedListMode = window.isMobile ? 'mobile_cards' : ($localStorage.categorylistModeLayout || 'full');
    $scope.sortMode = CatalogServiceHelper.lastUsedSortMode = window.taxonSortMode || $scope.sortMode; // read default sort mode from window globals (set in spree/taxons/show.html template)
    $scope.filterQuery = null;

    $scope.productSearchTerm = window.productSearchTerm;

    /**
     * Indicates if the viewer is in a keyword search mode
     * @type {boolean}
     */
    $scope.isKeywordSearch = $scope.productSearchTerm != null;

    /**
     * Pagination info, reference must be presevered for paginator to work.
     *
     * @type {{}}
     */
    $scope.pagination = {
      page: $location.search().page || 1,
      per_page: $location.search().per_page || 144
    };

    $scope.paginator = new ProductPaginator($scope, $scope.pagination);

    $rootScope.infiniteScrollIsActive = false;
    $rootScope.abTaxonShowMoreButton = null;

    $rootScope.isWineSection = false;
    // getJumboHeights();

    CatalogServiceHelper.isSearchView = false;

    /**
     * Loads a product collection based on selected filters
     */
    $scope.loadProducts = function() {
      const searchEngine = $location.search().search_engine;
      const debugSearch = $location.search().debug_search;

      return $q((resolve, reject) => {
        var params = { template: 'product_in_catalog' };
        params.filters = 't';
        params.page = $scope.pagination.page;
        params.per_page = $scope.pagination.per_page;
        params.sort_mode = window.lastUsedSortMode = $scope.sortMode;
        if ($scope.sortMode === 'popularity' && $scope.popAlgorithm) params.pop_algorithm = $scope.popAlgorithm;
        if (searchEngine) params.search_engine = searchEngine;
        if (debugSearch) params.debug_search = debugSearch;

        params.zipcode = window.currentZipcode;
        params.locale = $translate.use();
        params.express_delivery = CartData && CartData.isExpressDelivery ? 't' : '';

        if ($location.search().explain || window.explainProductList)
          params.explain = 't';

        if (window.CategoryDebugTool) {
          params.include_unaddable = window.CategoryDebugTool.params.include_unaddable
        }

        NProgress.start();

        if (window.CatalogFilterCtrl) {
          var filters = window.CatalogFilterCtrl.getSelection();

          params.s = filters;
          params.s.hub_ids = Hubs.currentHubId;
        }

        // Inject taxon id from the URL, if nothing is set in the filter
        if (params.s == null || (params.s['taxon_ids'] == null && params.s.taxon_ids == null))
          params['s[taxon_ids]'] = $scope.taxonIds;

        if (params.s == null)
          params['s[hub_ids]'] = Hubs.currentHubId;

        $scope.isLoading = true;
        CatalogServiceHelper.setLoading(true);

        $scope.currentLoadProductsRequest = $q.defer();

        $http.get(`/api/products.json?${$.param(params)}`, {timeout: $scope.currentLoadProductsRequest.promise}).then((result) => {
          setTimeout(() => NProgress.done(), 250);

          // Abort if the paginator is not available (means the view is destroyed)
          if ($scope.paginator == null) {
            CatalogServiceHelper.setLoading(false);
            return resolve(false);
          }

          if (_destroyed) {
            CatalogServiceHelper.setLoading(false);
            reject(false);
            return;
          }

          if (UserService.isAdmin && result.data.explain_url) {
            window.open(result.data.explain_url);
          }

          $scope.paginator.updatePaginationFromResponse(result.data);

          if ($scope.pagination.current_page == 1 || $scope.products == null || $scope.products.length == 0) {
            $scope.products = result.data.products
          } else { // Non-first page will merge results
            let newProducts = _.select(result.data.products, p => _.findIndex($scope.products, ep => ep.id == p.id) == -1);
            $scope.products.push.apply($scope.products, newProducts);
            // _.each(result.data.products, p => _.findIndex($scope.products, ep => ep.id == p.id) == -1 && $scope.products.push(p))
          }

          // Inject and update filters into the neighboring filters controller, if present
          if (result.data.filters) {
            if (window.CatalogFilterCtrl) {
              $timeout(() => {
                if (window.CatalogFilterCtrl) {
                  window.CatalogFilterCtrl.updateFilters(result.data.filters);
                  $rootScope.$broadcast('catalog:filters:loaded', { filters: result.data.filters, sender: $scope, viewmode: 'catalog' })
                  CatalogServiceHelper.onFilterSelectionChange();
                  $timeout(() => {
                    $scope.isLoading = false;
                    CatalogServiceHelper.setLoading(false)
                  }, 10);
                }
              });
            } else {
              $scope.filters = result.data.filters;
              $rootScope.$broadcast('catalog:filters:loaded', { filters: $scope.filters, sender: $scope, viewmode: 'catalog' });

              $timeout(() => {
                $scope.isLoading = false;
                CatalogServiceHelper.setLoading(false);
              }, 10);
            }
          } else {
            // Allow a small delay before announcing load complete
            $timeout(() => {
              $scope.isLoading = false;
              CatalogServiceHelper.setLoading(false);
            }, 10);
          }

          resolve($scope.products);
        })
      });
    };

    $scope.update = function(options) {
      if (options && options.resetPage)
        $scope.pagination.page = $scope.pagination.current_page = 1;

      if (options && options.resetProducts && $scope.products)
        $scope.products.length = 0;

      if ($scope.taxonIds == null) {
        $scope.taxonIds = [$scope.taxonData.id];
      } else if (window.CatalogFilterCtrl) {
        var filters = window.CatalogFilterCtrl.getSelection();
        if (filters.taxon_ids) $scope.taxonIds = filters.taxon_ids;

        // Set url parameters to match the filter selection, so that the URL is shareable
        if (!window.$locationChanging) {
          const searchEngine = $location.search().search_engine;
          const debugSearch = $location.search().debug_search;

          const locationParams = CatalogServiceHelper.serializeFilterSelectionToUrlParams(filters, { taxon_ids: false });

          if ($scope.sortMode && $scope.sortMode != DEFAULT_SORTING) {
            locationParams.sort_mode = $scope.sortMode;
          }

          if (searchEngine) {
            locationParams.search_engine = searchEngine;
          }

          if (debugSearch) {
            locationParams.debug_search = debugSearch;
          }

          $location.changeSearch(locationParams);
          Tracking.sendPageviewExt($location.url());
        }
      }

      var noCategoryView = $scope.taxonIds == null || $scope.taxonIds.length == 0;
      var singleCategoryView = $scope.taxonIds && $scope.taxonIds.length == 1;
      var withLoadingTaxon = false;

      let requestLocale = $translate.use();

      // Cancel previously open requests
      if ($scope.currentLoadProductsRequest && $scope.currentLoadProductsRequest.resolve) {
        $scope.currentLoadProductsRequest.resolve();
      }

      return $q((resolve, reject) => {
        $scope.loadProducts().then((r) => {
          if (singleCategoryView &&
            ($scope.currentTaxon == null || $scope.currentTaxon.id != $scope.taxonIds[0])) {
            withLoadingTaxon = true;

            TaxonHelper.getTaxonInfo($scope.taxonIds[0], { description: true, include_direct_children: true, seo_url: true, alternate_seo_urls: true }).then((taxonData) => {
              $scope.taxonData = $scope.currentTaxon = taxonData;
              CatalogServiceHelper.setCurrentTaxon($scope.taxonData);

              $scope.currentTaxonId = taxonData.id;
              setMetaAndOgDataForTaxon(taxonData);

              if (!firstLocationLoad && !_destroyed) {
                if (!window.$locationChanging) {
                  $location.changeUrl("/" + taxonData.permalink, {prependLocale: true, replace: true});
                  Tracking.sendPageviewExt($location.url());
                } else console.warn("ABORTED changeUrl because location change is in progress!");
              }

              // A HACK to make farmyNgView onTranslateSuccess reload work as expected:
              // inject a category template header set (if not available already) for the loaded taxons.
              // Unfortunately multiple keys need to be filled to match different loading cases
              // TODO: Think about a proper fix for the locale-switching system
              let taxonTemplateCacheKeys = [
                `${requestLocale == 'de' ? '' : `/${requestLocale}`}/${taxonData.permalink}`,
                `${requestLocale == 'de' ? '' : `/${requestLocale}`}/${taxonData.permalink}?locale=${requestLocale}&ng_view_load=t`,
                `${requestLocale == 'de' ? '' : `/${requestLocale}`}/${taxonData.permalink}?&locale=${requestLocale}&ng_view_load=t`,
              ];

              if (window._templateHeadersCache) {
                _.each(taxonTemplateCacheKeys, taxonTemplateCacheKey => {
                  if (window._templateHeadersCache[taxonTemplateCacheKey] == null && taxonData.alternate_urls) {
                    window._templateHeadersCache[taxonTemplateCacheKey] = window._templateHeadersCache[taxonTemplateCacheKey] || {};

                    for(var localeKey in taxonData.alternate_urls) {
                      window._templateHeadersCache[taxonTemplateCacheKey][`alt_url_${localeKey}`] = taxonData.alternate_urls[localeKey]
                      window._templateHeadersCache[taxonTemplateCacheKey][`alt_url_${localeKey}`] = taxonData.alternate_urls[localeKey]

                    }
                  }
                });
              }

              setTimeout(() => { if (window.PageloadTracking) PageloadTracking.viewloadSummaryEnd() }, 10);
            })
          }

          if (!firstLocationLoad) {
            setTimeout(() => {
              if ($scope.pagination.page == 1) CatalogServiceHelper.scrollToCatalogHead({ ifBelow: true });
            }, 200);
          }

          firstLocationLoad = false;

          resolve(r);
        })
      });
    };

    /**
     * Changes the template mode of product items (from 'full' to 'list', etc.)
     *
     * @param mode
     * @param locale
     */
    $scope.setListingMode = function(mode, locale) {
      $scope.listMode = mode;

      $scope.update();

      ahoy.track("catalog-listing-changed", { mode: mode, taxon_id: $scope.taxonId, taxon_permalink: $scope.taxonPermalink, channel: window.xSessionChannel });
    };

    /**
     * Callback for when the subcategory dropdown is changed (only on mobile)
     */
    $scope.onDropdownSubcategorySelected = function() {

    };

    /**
     * Sets sorting and reloads the view
     *
     * @param sortMode
     */
    $scope.sortProductsBy = function(sortMode) {
      if ($scope.sortMode == sortMode) {
        var baseSort = sortMode.replace('_asc', '').replace('_desc', '');
        if (sortMode.indexOf('_asc') > -1)
          sortMode = baseSort + '_desc';
        else if (sortMode.indexOf('_desc') > -1)
          sortMode = baseSort + '_asc';
      }

      $scope.sortMode = sortMode;
      CatalogServiceHelper.lastUsedSortMode = sortMode;

      $scope.update({resetPage: true});
      ahoy.track("catalog-sorting-changed", { mode: sortMode, taxon_id: $scope.taxonId, taxon_permalink: $scope.taxonPermalink, channel: window.xSessionChannel });
      trackEvent('category_sort', {gtmObject: {type: sortMode}});
    };

    $scope.setProductsLayout = function(layout) {
      if (layout == 'default') {
        layout = window.isMobile ? 'mobile_cards' : 'full';
      }

      ahoy.track("catalog-layout-changed", { mode: layout, taxon_id: $scope.taxonId, taxon_permalink: $scope.taxonPermalink, channel: window.xSessionChannel });

      CatalogServiceHelper.lastUsedListMode = layout;
      $localStorage.categorylistModeLayout = layout;
      $scope.listMode = layout;
    };

    // locale here is "" for de and "/en" for en
    $scope.onMobileFilterChange = function(locale) {
      //
      // TODO: Hook up with the new system
      //
      if ($scope.mobileFilterSelectedCategory != null) {
        var url = sprintf("%s/%s", locale, $scope.mobileFilterSelectedCategory);
        $scope.loadView(url);

        Tracking.productSubcategoryNavigation($scope.mobileFilterSelectedCategory);
      }
    };

    /**
     * Adds a farmy-below-browse-addon element after the 12th product in the list
     *
     * TODO: Needs to be refactored to support API-based catalog loading
     */
    $scope.addLeadOutModule = function() {
      var listQuery = '.products-list .products-list-item';
      var el = $compile("<div class='col-xs-12 info-entry'><farmy-below-browse-addon style='margin-top: 20px; margin-bottom: 20px;' /></div>")($scope);
      var totalCount = $element.find(listQuery).length;

      if (totalCount > 0) {
        var position = Math.min(totalCount, 12);
        el.insertAfter($element.find(`.products-list .col-xs-12:nth-child(${position})`)[0]);
      }
    };

    // Private
    var firstLocationLoad = true;
    var _destroyed = false;

    /**
     * Dynamically changes contents of meta and OpenGraph tags after
     * reloading taxon data via Ajax
     *
     * @param taxonData
     */
    function setMetaAndOgDataForTaxon(taxonData) {
      window.document.title = taxonData.meta_title;

      if ($("meta[name='title']").length == 0) $('head').append(`<meta name="title" content="${taxonData.meta_title}">`);
      else $("meta[name='title']").attr('content', taxonData.meta_title);
      $("meta[name='description']").attr('content', taxonData.meta_description);
      $("meta[name='keywords']").attr('content', taxonData.meta_keywords);
      $("link[rel='canonical']").attr('href', taxonData.canonical_url);

      let robotTagValue ='INDEX,FOLLOW' ;
      if (!taxonData.permalink) {
        robotTagValue = 'NOINDEX,FOLLOW';
      }
      $rootScope.setRobotsMeta(robotTagValue);

      if (taxonData.alternate_seo_urls) {
        _.each(I18n.availableLocales, locale => {
          $(`link[hreflang='${locale == 'de' ? 'x-default' : locale}']`).attr('href', taxonData.alternate_seo_urls[locale]);
        });
      }

      $("meta[property='og:description']").attr('content', taxonData.meta_description);
      $("meta[property='og:site_name']").attr('content', taxonData.meta_title);
      $("meta[property='og:title']").attr('content', taxonData.name + ' – ' + taxonData.meta_title);
      $("meta[property='og:url']").attr('content', taxonData.canonical_url);
    }

    function checkWineSection() {
      var oldValue = $rootScope.isWineSection;

      if ($scope.isKeywordSearch) {
        $rootScope.isWineSection = false;
        return;
      }

      $rootScope.isWineSection = $scope.wineTaxonIds.indexOf($scope.currentTaxonId) > -1 || $scope.wineTaxonPermalinks.indexOf($scope.taxonId.toString().split('/').slice(-1)[0]) > -1;
      if ($rootScope.isWineSection != oldValue) {
        if ($rootScope.isWineSection) {
          $timeout(function() {
            onWineSectionEnter()
          }, 100);
        } else {
          onWineSectionLeave()
        }
      }
    };

    function onWineSectionLeave() {
      // $($scope.wineJumboContentElement).fadeOut(1000, function() {
      //   $($scope.wineJumboElement).addClass('shrunk');
      // })
    };

    function onWineSectionEnter() {
      // $($scope.wineJumboElement).removeClass('shrunk');
      // $timeout(function() {
      //   $($scope.wineJumboContentElement).fadeIn(1000);
      // }, 1100);
    };

    function getJumboHeights() {
      $timeout(function() {
        $scope.jumboHeight = $('.jumbotron .container').outerHeight() + 65;

        $scope.wineJumboElement = $('.jumbotron-wrapper');
        $scope.wineJumboContentElement = $('.jumbotron-wrapper .jumbotron');
      }, 100)
    };

    function setupPopAlgorithm() {
      $scope.popAlgorithm = localStorage.getItem('farmyPopAlgorithm');
      $scope.popAlgorithm = $scope.popAlgorithm === 'null' ? null : $scope.popAlgorithm;

      $scope.$watch('popAlgorithm', (newVal, oldVal) => {
        localStorage.setItem('farmyPopAlgorithm', $scope.popAlgorithm);

        if (newVal !== oldVal) $scope.loadProducts();
      })
    }

    // Initialization
    $scope.$on('$destroy', destructor);
    constructor();
  }
]);
