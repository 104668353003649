import * as _ from 'underscore';
import * as sprintf from 'sprintf';
import {trackPageView} from '../services/tracking-helper';

'use strict';

angular.module('app').controller('CheckoutShippingCtrl', ['$scope', '$sce', '$http', '$q', '$rootScope', '$timeout', '$element', 'CartData', 'Alerts', 'blockUI', 'PromisedElement', 'AddressHelper', function ($scope, $sce, $http, $q, $rootScope, $timeout, $element, CartData, Alerts, blockUI, PromisedElement, AddressHelper) {
  window.CheckoutShippingCtrl = $scope;

  $scope.checkoutCtrl = $scope.$parent.$parent;

  function constructor() {
    $scope.$watch('checkoutCtrl.stage', (newValue, oldValue) => {
      if ($scope.checkoutCtrl.isCheckoutStageAfter('shipping') &&
        ($scope.checkoutCtrl.shipAddress || $scope.checkoutCtrl.pickupPoint)) {
        $scope.updateShippingDestinationMap();
      }
      if (newValue === 'shipping')
        trackPageView('checkout', {products: $scope.checkoutCtrl.checkout?.line_items, checkout: $scope.checkoutCtrl.checkout});
    });

    $scope.$watch('checkoutCtrl.checkout', (newValue, oldValue) => {
      if (newValue != null) {
        $timeout(() => {
          if (!$scope.checkoutCtrl.shippingMethods || $scope.checkoutCtrl.shippingMethods.length == 0)
            $scope.checkoutCtrl.getShippingMethods()
        }, 1)
      }
    })
  }

  function destructor() {
    window.CheckoutShippingCtrl = null;
  }

  // Public members

  /**
   * Update the shipping destination google map for either delivery or pickup location
   * NOTE mike: now returns a promise to allow 'loader' animation to cover the whole render
   */
  $scope.updateShippingDestinationMap = function() {
    return $q(function(resolve, reject) {
      $scope.checkoutCtrl.getMapsApi().then(function() {
        if ($scope.shippingDestinationMap == null) {
          const defaultCenter = new window.google.maps.LatLng(47.3295618, 8.4789311);

          const mapOptions = {
            zoom: 15,
            center: defaultCenter,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
            mapId: "shipping-destination-map"
          };

          PromisedElement.promise($element, '.shipping-destination-map').then(function(el) {
            var el = el.get(0);
            $scope.shippingDestinationMap = new window.google.maps.Map(el, mapOptions);
            $(el).addClass('active');

            if ($scope.checkoutCtrl.checkout.delivery_mode == 'deliver') {
              //
              // Location for delivery to customer
              //
              const address = $scope.checkoutCtrl.shipAddress;

              AddressHelper.geocodeAddress(address).then(function(coords) {
                $scope.geocodingSuccessful = true;

                $scope.shippingDestinationMap.center = new window.google.maps.LatLng(coords?.lat, coords?.lng);

                if ($scope.destinationMapMarker == null) {
                  $scope.destinationMapMarker = new window.google.maps.marker.AdvancedMarkerElement({
                    map: $scope.shippingDestinationMap,
                    position: coords
                  });
                }
                resolve(coords);
              }, (e) => {
                $scope.geocodingSuccessful = false;
                reject(false);
              });
            } else if ($scope.checkoutCtrl.pickupPoint && $scope.checkoutCtrl.pickupPoint.lat) {
              //
              // Location for pickup
              //
              $scope.geocodingSuccessful = true;

              const coords = {lat: parseFloat($scope.checkoutCtrl.pickupPoint.lat), lng: parseFloat($scope.checkoutCtrl.pickupPoint.lng)};

              if ($scope.destinationMapMarker == null) {
                $scope.destinationMapMarker = new window.google.maps.marker.AdvancedMarkerElement({
                  map: $scope.shippingDestinationMap,
                  position: coords
                });
              }

              $scope.shippingDestinationMap.center = new window.google.maps.LatLng(coords?.lat, coords?.lng);
              resolve(coords);
            }
          });
        } else { // Reuse existing map
          const address = $scope.checkoutCtrl.shipAddress

          AddressHelper.geocodeAddress(address).then(function(coords) {
            $scope.geocodingSuccessful = true;

            $scope.shippingDestinationMap.center = new window.google.maps.LatLng(coords?.lat, coords?.lng);

            if ($scope.destinationMapMarker == null) {
              $scope.destinationMapMarker = new window.google.maps.marker.AdvancedMarkerElement({
                map: $scope.shippingDestinationMap,
                position: coords
              });
            }
            resolve(coords);
          }, (e) => {
            $scope.geocodingSuccessful = false;
            reject(false);
          });
        }
      });
    });
  };

  // Initialize

  $scope.$on('$destroy', destructor);
  constructor();
}]);