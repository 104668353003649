import * as sprintf from "sprintf";
import globalState from "../../shared-services/globalState";

angular.module('app').controller('ProfilePageCtrl', ['$scope', '$rootScope', '$location', '$window', '$interval', '$http', '$element', '$localStorage', 'Alerts',
  function ($scope, $rootScope, $location, $window, $interval, $http, $element, $localStorage, Alerts) {
    // console.log("ProfilePageCtrl controller initialized with scope");

    window.ProfilePageCtrl = $scope;
    const {getGlobalState} = globalState();
    const {setPageCode} = getGlobalState("router");
    setPageCode("favorites");

    $scope.loadUserProfile = function() {
      const userId = window.currentUserId;

      if (userId) {
        $http.get(`api/frontend/profiles/${userId}.json?favorites=t`).then((response) => {
          $scope.userProfile = response.data.profile;
          window.currentUserFavoritesIds = response.data?.profile?.favorites.map(favorite => favorite.id);
        });
      }
    };

    $scope.sharePage = function() {
      FB.ui({
        display: 'popup',
        method: 'feed',
        link: window.location.href,
        picture: $('.profile-about-block img').attr('src'),
        description: sprintf('Foodie-Profile von %s', $('.profile-about-block .sub').text().trim()),
        caption: sprintf('Farmy.ch – %s', $('.profile-about-block .user-nickname').text().trim())
        // action_type: 'og:likes',
        // action_properties: JSON.stringify(itemObject)
      }, function(response) {

      });
    };

    $scope.updateFavorite = function(favorites) {
      setTimeout(function() {
        $scope.userProfile = {...$scope.userProfile, favorites};
      }, 200);
    };

    $scope.loadUserProfile();

    $scope.$on('favorites:updated', (event, response, productId) => {
      $scope.updateFavorite(response?.currentUser?.favorites);
    });
  }
]);
